import React, { useState } from 'react';
import styles from './AuditForm.module.css';
import { Error } from "../../components/Error";
import { useHistory } from 'react-router-dom';
import DocumentTitle from "react-document-title";

const AuditForm = ({ server }) => {
  const [first_url, set_url] = useState("");
  const [standard, set_standard] = useState("wcag2aa");
  const [check_sub_domains, set_check_sub_domains] = useState(false);
  const [maxDepth, set_max_depth] = useState(5);
  const [max_pages_per_domain, set_max_pages_per_domain] = useState(0);
  const [sitemaps, set_sitemaps] = useState(false);
  const [browser, set_browser] = useState('chrome');
  const [post_loading_delay, set_post_loading_delay] = useState(0);
  const [standardsDisabled, setStandardsDisabled] = useState(true);
  const [browserDisabled, setBrowserDisabled] = useState(true);
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const history = useHistory();

  function validateForm() {
    return true;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Starting audit");
    console.log(server.startAudit);
    console.log("\n\n " + max_pages_per_domain)
    try {
      const params = {
        firstURL: first_url,
        standard: 'wcag21aa',
        checkSubdomains: check_sub_domains,
        maxDepth: maxDepth,
        maxPagesPerDomain: max_pages_per_domain,
        sitemaps: sitemaps,
        includeMatch: '',
        browser: 'chrome',
        postLoadingDelay: post_loading_delay,
      };
      const audit = await server.startAudit(params);
      console.log(audit);
      history.push('/audits/' + audit.id + '/status');
    } catch (error) {
      console.log(error);
      setError(error.message);
      setShow(true);
    }
  }

  return (
    <DocumentTitle title="Add new audit">
      <div className={styles.audit_form_container}>
        {error != null &&
          <Error error_message={error} setShow={setShow} show={show} />
        }
        <div className={styles.audit_form_box}>
          <h2>Create Audit</h2>

          <form onSubmit={async (e) => handleSubmit(e)} style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px" }}>
            {/* <p style={{alignSelf:'flex-start'}}>Required fields are marked by * symbol (Asterisk)</p> */}
            <div className={styles.form_group}>
              <label htmlFor="url">Enter URL</label>
              <input id='url' type="url" placeholder="https://example.com/" name="url" value={first_url} className={styles.input_field} onChange={(e) => set_url(e.target.value)} required />
            </div>
            <div className={styles.form_group}>
              <label htmlFor="standard">Standard</label>
              {/* <select id="standard" className={styles.standard_dropdown} value={standard} onChange={(e) => set_standard(e.target.value)} disabled={standardsDisabled}>
                <option value="wcag2a">WCAG 2.0 Level A</option> */}
              {/* <option value="wcag2aa">WCAG 2.0 Level AA</option>
              <option value="wcag21aa">WCAG 2.1 Level AA</option>
            <option value="section508">Section 508</option> */}
              {/* </select> */}
              <div className={styles.pseudo_dropdown}>WCAG 2.1 Level AA</div>
            </div>
            <div className={styles.checkbox_wrapper}>
              <div>
                <input type="checkbox" id="cbsubdomains" style={{ marginRight: "10px" }} value={check_sub_domains} checked={check_sub_domains} onChange={e => set_check_sub_domains(e.target.checked)} />
                <label htmlFor="cbsubdomains">Check Subdomains</label>
              </div>
              <div>
                <input type="checkbox" id="cbsitemap" style={{ marginRight: "10px" }} value={sitemaps} onChange={e => set_sitemaps(e.target.checked)} checked={sitemaps} />
                <label htmlFor="cbsitemap">Use sitemaps for page discovery</label>
              </div>
            </div>
            <div className={styles.form_group}>
              <label htmlFor="maxcrawl">Maximum Crawling Depth</label>
              <input type="number" id="maxcrawl" placeholder="Maximum Crawling Depth" name="max_crawl" value={maxDepth} min={0} max={5} className={styles.input_field} onChange={(e) => set_max_depth(parseInt(e.target.value))} />
            </div>
            <div className={styles.form_group}>
              <label htmlFor="maxpages">Maximum Pages Per Domain</label>
              <input type="number" id="maxpages" placeholder="Maximum Pages Per Domain" name="max_domain" value={max_pages_per_domain} min={0} className={styles.input_field} onChange={(e) => set_max_pages_per_domain(parseInt(e.target.value))} />
            </div>
            {/* <input type="text" placeholder="Include Match" name="include_match" value={matchPath} className={styles.input_field} onChange={(e) => set_url(e.target.value)} /> */}
            <div className={styles.form_group}>
              <label htmlFor="url">Browser</label>
              {/* <select className={styles.standard_dropdown} value={browser} onChange={e => set_browser(e.target.value)} disabled={browserDisabled}>
                <option value="chrome">Chrome</option>
              </select> */}
              <div className={styles.pseudo_dropdown}>Chrome</div>
            </div>
            <div className={styles.form_group}>
              <label htmlFor="postload">Post Loading Delay</label>
              <input type="number" id="postload" placeholder="Post Loading Delay" name="post_delay" value={post_loading_delay} min={0} max={500} className={styles.input_field} onChange={(e) => set_post_loading_delay(parseInt(e.target.value))} />
            </div>
            <div className={styles.btn_container}>
              <button type="submit" className={styles.audit_button} disabled={!validateForm()} >Start Audit</button>
            </div>
          </form>
        </div>
      </div>
    </DocumentTitle>
  );

}

export default AuditForm;
