import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import { useParams } from "react-router-dom";
import styles from './Analytics_Page.module.css';
import DocumentTitle from "react-document-title";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import { AiOutlineArrowDown } from "react-icons/ai"
import { Breadcrumb } from "react-bootstrap";
import { FiFilter } from "react-icons/fi"


// Can combine this and above into one function and return an array which can be destructured. Will test after finishing this first.
const calculate_accessiblity_score = (nodes) => {
    let score = 0;
    nodes.forEach((node) => {
        switch (node.Issue.impact) {
            case "critical":
                score += 2;
                break;
            case "serious":
                score += 4;
                break;
            case "moderate":
                score += 6;
                break;
            case "minor":
                score += 8;
                break;
            default:
                score += 10;
                break;
        }
    });
    return Math.round(((score / 10) / (nodes.length)) * 100);
}

export const AnalyticsPage = ({ server }) => {
    const { page_id } = useParams();
    const [nodes, set_nodes] = useState([]);
    const [categories, set_categories] = useState();
    const [allCategories, setAllCategories] = useState();
    // const [score, set_score] = useState(0);
    // const [data, set_data] = useState();
    const [filter, set_filter] = useState("all");
    const [severity, setSeverity] = useState("all");    
    const [pageTitle, setPageTitle] = useState("");


    // const impact_data = useMemo(() => extract_issue_impact(nodes), [nodes]);
    // const calculated_score = useMemo(() => calculate_accessiblity_score(audit), [audit]);

    function convert_nodes_to_table_format(nodes) {
        const issues = nodes.reduce(function (r, a) {
            r[a.Issue.description] = r[a.Issue.description] || [];
            r[a.Issue.description].push(a);
            return r;
        }, Object.create(null));
        // console.log(issues);
        return issues;
    }

    const handleCategoryChange = (e) => {
        console.log(e.target.value)
        set_filter(e.target.value)
    }

    const Violations_Table = ({ nodes, filter }) => {
        let converted_nodes = convert_nodes_to_table_format(nodes);
        // console.log(converted_nodes)
        return (<div style={{ overflowY: "scroll" }} >
            <Card className={styles.outer_box}>
                <Card.Header style={{ fontSize: "20px", fontWeigth: "500" }}>
                    <h3 className={styles.violation_heading}>{filter} violations</h3>
                </Card.Header>
                <Table bordered hover responsive size="sm">
                    <tbody>
                        {Object.keys(converted_nodes).map(desc => {
                            if ((converted_nodes[desc][0].Issue.category === filter && converted_nodes[desc][0].Issue.impact === severity) || (converted_nodes[desc][0].Issue.category === filter && severity === 'all') || (filter === 'all' && converted_nodes[desc][0].Issue.impact === severity) || (filter === 'all' && severity === 'all'))
                                return (<TableRow desc={desc + " (" + converted_nodes[desc].length + ")"} nodes={converted_nodes[desc]} filter={"structure"} key={desc} />)
                        })}
                    </tbody>
                </Table>
            </Card>
        </div>)
    }

    // Make more nicer.
    const TableRow = ({ desc, nodes, filter }) => {
        // console.log("entering table row");
        // console.log("Desc: " + desc + "\nNodes" + nodes);
        let [expanded, set_expand] = useState(false);
        // let issue = nodes[0].Issue;

        return (
            [<tr onClick={() => set_expand(expand => !expand)} key={desc} className={styles.tablerow} >
                <td colSpan={4} style={{ textAlign: "left", paddingLeft: "20px", fontSize: "18px" }}><div className={styles.table_data} tabIndex="0" role="button" aria-expanded={expanded}>{desc} <AiOutlineArrowDown /> </div></td>
            </tr>,
            expanded && <Table bordered>
                <thead>
                    <tr>
                        <th>Path</th>
                        <th>Snippet</th>
                        <th>Impact</th>
                        <th>Recommendations</th>
                    </tr>
                </thead>
                {
                    nodes.map(node => {
                        // console.log(node.Issue.category)
                        // if (node.Issue.category === filter || filter === "all") {
                        return <tr key={node.id}>
                            <td style={{ border: "1px solid lightgray" }}><span>{node.target}</span></td>
                            <td style={{ border: "1px solid lightgray" }}><span>{node.html}</span></td>
                            <td style={{ border: "1px solid lightgray" }}><span>{node.Issue.impact}</span></td>
                            <td style={{ border: "1px solid lightgray" }}><span>{node.Issue.help}</span></td>
                        </tr>
                        // }
                    })
                }
            </Table>
            ]
        )
    }

    const SeverityTable = () => {
        return (
            <table className={styles.severity_table}>
                <thead>
                    <tr>
                        <th><button onClick={(e) => setSeverity("all")} aria-pressed={severity === 'all'}>All <FiFilter /> </button></th>
                        <th><button onClick={(e) => setSeverity("critical")} aria-pressed={severity === 'critical'}>Critical <FiFilter /> </button></th>
                        <th><button onClick={(e) => setSeverity("serious")} aria-pressed={severity === 'serious'}>Serious <FiFilter /> </button></th>
                        <th><button onClick={(e) => setSeverity("moderate")} aria-pressed={severity === 'moderate'}>Moderate <FiFilter /> </button></th>
                        <th><button onClick={(e) => setSeverity("minor")} aria-pressed={severity === 'minor'}>Minor <FiFilter /> </button></th>
                    </tr>

                    {filter !== 'all' ? (
                        <tr>
                            <td>{nodes.filter(node => node.Issue.category === filter).length}</td>
                            <td>{nodes.filter(node => node.Issue.impact === 'critical' && node.Issue.category === filter).length}</td>
                            <td>{nodes.filter(node => node.Issue.impact === 'serious' && node.Issue.category === filter).length}</td>
                            <td>{nodes.filter(node => node.Issue.impact === 'moderate' && node.Issue.category === filter).length}</td>
                            <td>{nodes.filter(node => node.Issue.impact === 'minor' && node.Issue.category === filter).length}</td>
                        </tr>
                    ) : (
                        <tr>
                            <td>{nodes.length}</td>
                            <td>{nodes.filter(node => node.Issue.impact === 'critical').length}</td>
                            <td>{nodes.filter(node => node.Issue.impact === 'serious').length}</td>
                            <td>{nodes.filter(node => node.Issue.impact === 'moderate').length}</td>
                            <td>{nodes.filter(node => node.Issue.impact === 'minor').length}</td>
                        </tr>
                    )
                    }

                </thead>
            </table>
        )
    };


    const CategoryTable = () => {
        return (
            <div className={styles.category_container}>
                <div className={styles.severity_table_container}>
                    <h3>Filter by Severity: </h3>
                    <SeverityTable />
                </div>
                <div className={styles.filter_container}>
                    <div className={styles.label_container}>
                        <label id="filterlabel" className={styles.filter_label}>Filter By Category:</label>
                    </div>
                    <select name="filter" style={{ textTransform: "capitalize" }} className={styles.category_dropdown} onChange={(e) => { handleCategoryChange(e) }} value={filter} aria-labelledby="filterlabel">
                        {
                            allCategories !== undefined && allCategories.map((_category, idx) => {
                                return (
                                    <option key={idx} value={_category} style={{ textTransform: "capitalize" }}>{_category}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <table className={styles.category_table}>
                    <thead>
                        <tr>
                            <th>Category</th>
                            <th>Violations</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            categories !== undefined &&
                            Object.keys(categories).map((_category, idx) => {
                                return (
                                    <tr key={idx}>
                                        <th>{_category}</th>
                                        <td>{categories[_category]}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }

    useEffect(() => {
        const get_page_from_id = async (page_id) => {
            let nodes = await server.get_page_from_id(page_id);
            return nodes;
        }

        get_page_from_id(page_id).then(nodes => {
            set_nodes(nodes);
            setPageTitle(nodes[0].Page.url);
            let _category = {}
            nodes.map((node) => {
                let impact = node.Issue.impact;
                if (_category[node.Issue.category] === undefined) {
                    _category[node.Issue.category] = 1
                }
                else {
                    _category[node.Issue.category]++;
                }
            })
            set_categories(_category)
            let all_categories = ["all"]
            all_categories = all_categories.concat(Object.keys(_category))
            setAllCategories(all_categories);
        })
            .catch(e => {
                console.error("Page fetching error " + e);
                return [];
            })
    }, []);

    let page_title = JSON.stringify(pageTitle).replace('"https://', '').replace('"', '')
    page_title = page_title.substring(0, page_title.length - 1)

    const Breadcrumbs = () => {
        return (
            <Breadcrumb>
                {/* <Breadcrumb.Item href={"/"}> <span className='bc_item'>Home</span></Breadcrumb.Item> */}
                <Breadcrumb.Item href={"/audits"}> <span className='bc_item'>Audits</span></Breadcrumb.Item>
                <Breadcrumb.Item href={"/analytics/"}><span className="bc_item">Projects</span></Breadcrumb.Item>
                <Breadcrumb.Item href={"/analytics/" + page_title}> <span className='bc_item'>Domain Audits</span></Breadcrumb.Item>
                <Breadcrumb.Item active> <span className='active_bc_item'>Page Analytics for {page_title}</span></Breadcrumb.Item>
            </Breadcrumb>
        );
    }

    return (
        <DocumentTitle title="Detailed analytics report">
            <>
                <div className={styles.breadcrumb_container}>{Breadcrumbs()}</div>
                <div className={styles.container}>
                    <div className={styles.left_container}>
                        <h2 className={styles.page_analytics_heading}>{page_title}</h2>
                        <Violations_Table nodes={nodes} filter={filter} />
                    </div>
                    <div className={styles.right_container}>
                        <div className={styles.wrapper}>
                            <span className={styles.score_heading}>Page Accessibility Score</span>
                            <div className={styles.speedometer_container} role="progressbar" aria-valuenow={calculate_accessiblity_score(nodes)} aria-labelledby="score-head" aria-min="0" aria-max="100" aria-valuetext={"Page accessibility score: " + calculate_accessiblity_score(nodes) + "%"}>
                                <CircularProgressbar value={calculate_accessiblity_score(nodes)} text={`${calculate_accessiblity_score(nodes)}%`} circleRatio={0.75} styles={buildStyles({
                                    rotation: 1 / 2 + 1 / 8,
                                    strokeLinecap: "butt",
                                    trailColor: "#eee"
                                })}
                                />
                            </div>
                        </div>
                        <CategoryTable />
                    </div>
                </div>
            </>
        </DocumentTitle>
    );
};


export default AnalyticsPage;
