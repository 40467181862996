import React from 'react'
import styles from "./AuthScreen.module.css"
import leraLogo from "../../assets/lera.png"
import { useHistory } from 'react-router-dom'
import DocumentTitle from "react-document-title";

const AuthScreen = () => {
  const history = useHistory()
  const routeChange = (path) => {
    history.push(path)
  }

  return (
    <DocumentTitle title="Welcome to Lera cloud">
    <div className={styles.auth_container}>
      <div className={styles.img_container}>
        <img src={leraLogo} alt="Lera logo" />
      </div>
      <div className={styles.brand_text}>LERA</div>
      <button className={styles.signup_button} onClick={() => { routeChange("sign-up") }}>Sign Up</button>
      <button className={styles.login_button} onClick={() => { routeChange("sign-in") }}>Log In</button>
    </div>
    </DocumentTitle>
  )
}

export default AuthScreen