import React, { useState, useEffect } from 'react';
import styles from './Admin.module.css';
import { useHistory } from 'react-router-dom';


const Admin = ({ server }) => {
  let history = useHistory();
  const [userCount, setUserCount] = useState(0)
  const [auditCount, setAuditCount] = useState(0)

  const handleAdminCardClick = (url) => {
    console.log(url)
    history.push(url)
  }

  useEffect(() => {
    const getUserCount = async () => {
      const usercountdata = await server.getUserCount()
      const auditcountdata = await server.getAuditCount()
      console.log(auditcountdata)
      setUserCount(usercountdata)
      setAuditCount(auditcountdata)
    }
    getUserCount()
  }, [])

  return (
    <div className={styles.container}>
      <h1>Admin Panel</h1>
      <div className={styles.admin_card_container}>
        <div className={styles.card_wrapper}>
          <button className={styles.admin_card} onClick={() => handleAdminCardClick("/admin/users")} > <h2>List All Users</h2> </button>
          <button className={styles.admin_card} onClick={() => handleAdminCardClick("/admin/audits")}> <h2>List All Audits</h2> </button>
        </div>
        <div className={styles.platform_status_container}>
          <div className={styles.audit_stats}>
            <div className={styles.stats_card}> <h2>Total Users on the Platform</h2> <span className={styles.stats_text}>{userCount}</span> </div>
          </div>
          <div className={styles.audit_stats}>
            <div className={styles.stats_card}> <h2>Total Scans using the Platform</h2> <span className={styles.stats_text}>{auditCount}</span> </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Admin;
