import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from 'react-bootstrap/Button';
import Card from "react-bootstrap/Card";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar
} from "recharts";
import { Legend } from "recharts";
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import styles from './Analytics_Domain.module.css';
import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import 'react-circular-progressbar/dist/styles.css';
import DocumentTitle from "react-document-title";
import { TiArrowUnsorted } from "react-icons/ti";
import Breadcrumb from 'react-bootstrap/Breadcrumb';


function Breadcrumbs() {
  let curr_url = window.location.href;
  return (
    <Breadcrumb>
      <Breadcrumb.Item href={curr_url + "/.."}> <span className='bc_item'>Home</span></Breadcrumb.Item>
      <Breadcrumb.Item href={curr_url + "/../../audits"}> <span className='bc_item'>Audits</span></Breadcrumb.Item>
      <Breadcrumb.Item href={curr_url + "/.."}> <span className='bc_item'>Projects</span></Breadcrumb.Item>
      <Breadcrumb.Item active> <span className='active_bc_item'>{curr_url.split('/').at(-1)}</span></Breadcrumb.Item>
    </Breadcrumb>
  );
}

export const AnalyticsDomain = ({ server }) => {
  const [selected, setSelected] = useState("new-audits");
  const { project_name } = useParams();
  const [criticalOrder, setCriticalOrder] = useState("");
  const [seriousOrder, setSeriousOrder] = useState("");
  const [moderateOrder, setModerateOrder] = useState("");
  const [minorOrder, setMinorOrder] = useState("");
  const [urlOrder, setUrlOrder] = useState("");
  const [vioOrder, setVioOrder] = useState("")
  const [dateTimeOrder, setDateTimeOrder] = useState("");
  const [view, setView] = useState("Table View")
  const [accessibleBarChartText, set_fuddu_text] = useState("");

  const [domain_audits, set_domain_audits] = useState([]);
  const [curr_audit, set_curr_audit] = useState([]);
  const [curr_page, set_curr_page] = useState(1);
  const [query, set_query] = useState(undefined);
  const [audit_pages, set_audit_pages] = useState([]);
  const [accessibility_score, set_accessibility_score] = useState(0);
  const [issue_impact, set_issue_impact] = useState([]);
  const [line_chart_data, set_line_chart_data] = useState([]);
  const [total_pages, set_total_pages] = useState(1);
  const page_list = new Array(total_pages).fill(1).map((_, i) => i + 1);
  const [severity, set_severity] = useState("serious");
  const [severity_order, set_severity_order] = useState("descending");
  const severity_option = ["critical", "serious", "moderate", "minor"];
  const order_option = ["descending", "ascending"];


  const handleDateClick = async () => {
    let sortedAudits;
    if (selected === "new-audits" || selected === "") {
      setSelected("old-audits")
      setDateTimeOrder("ascending");
      sortedAudits = await server.get_domain_audits_filtered(project_name, { 'param': 'createdAt', 'order': 'ASC' });
    }
    else {
      setSelected("new-audits")
      setDateTimeOrder("descending");
      sortedAudits = await server.get_domain_audits_filtered(project_name, { 'param': 'createdAt', 'order': 'DESC' });
    }
    setUrlOrder("");
    setVioOrder("");
    set_domain_audits(sortedAudits);
    // console.log("sorted by date")
  };

  // num of pages
  const handleUrlClick = async () => {
    setSelected("");
    setDateTimeOrder("");
    setVioOrder("");
    let sortedAudits = JSON.parse(JSON.stringify(domain_audits))
    sortedAudits.sort((a, b) => {
      if (urlOrder === "descending" || urlOrder === "") {
        setUrlOrder("ascending")
        return (a.urls - b.urls);
      }
      else {
        setUrlOrder("descending")
        return -(a.urls - b.urls);
      }
    })
    set_domain_audits(sortedAudits)
    // console.log("sorted by url")
  }

  // num of violation
  const handle_violation_click = () => {
    setSelected("");
    setDateTimeOrder("");
    setUrlOrder("");
    let sortedAudits = JSON.parse(JSON.stringify(domain_audits))
    sortedAudits.sort((a, b) => {
      if (vioOrder === "descending" || vioOrder === "") {
        setVioOrder("ascending")
        return (a.violations - b.violations)
      }
      else {
        setVioOrder("descending")
        return -(a.violations - b.violations)
      }
    })
    set_domain_audits(sortedAudits)
    // console.log("sorted by violation")
  }


  const handle_severity = async (selected_severity) => {
    let curr_severity = selected_severity;
    set_severity(curr_severity);
    let filter = { 'severity': curr_severity, 'order': severity_order === "descending" ? 'DESC' : 'ASC' };
    console.log(filter);
    let filtered_audit_pages = await server.get_audit_pages_filtered(curr_audit.id, query, filter);
    set_audit_pages(filtered_audit_pages);
  }


  const handle_severity_order = async (order) => {
    let curr_order = order;
    set_severity_order(curr_order);
    let filter = { 'severity': severity, 'order': curr_order === "descending" ? 'DESC' : 'ASC' };
    console.log(filter);
    let filtered_audit_pages = await server.get_audit_pages_filtered(curr_audit.id, query, filter);
    set_audit_pages(filtered_audit_pages);
  }


  // const handleImpactClick = (impact) => {
  //   const target = impact
  //   let sortedAudits = JSON.parse(JSON.stringify(curr_audit))
  //   sortedAudits.Pages.sort((a, b) => {
  //     if (target === "Critical") {
  //       setMinorOrder("");
  //       setModerateOrder("");
  //       setSeriousOrder("");
  //       if (criticalOrder === "descending" || criticalOrder === "") {
  //         setCriticalOrder("ascending")
  //         return (a.critical - b.critical);
  //       }
  //       else {
  //         setCriticalOrder("descending")
  //         return -(a.critical - b.critical);
  //       }
  //     }

  //     else if (target === "Serious") {
  //       setCriticalOrder("");
  //       setMinorOrder("");
  //       setModerateOrder("");
  //       if (seriousOrder === "descending" || seriousOrder === "") {
  //         setSeriousOrder("ascending")
  //         return (a.serious - b.serious);
  //       }
  //       else {
  //         setSeriousOrder("descending");
  //         return -(a.serious - b.serious);
  //       }
  //     }

  //     else if (target === "Moderate") {
  //       setCriticalOrder("");
  //       setMinorOrder("");
  //       setSeriousOrder("");
  //       if (moderateOrder === "descending" || moderateOrder === "") {
  //         setModerateOrder("ascending")
  //         return (a.moderate - b.moderate);
  //       }
  //       else {
  //         setModerateOrder("descending")
  //         return -(a.moderate - b.moderate);
  //       }
  //     }

  //     else if (target === "Minor") {
  //       setCriticalOrder("");
  //       setSeriousOrder("");
  //       setModerateOrder("");
  //       if (minorOrder === "descending" || minorOrder === "") {
  //         setMinorOrder("ascending")
  //         return (a.minor - b.minor)
  //       }
  //       else {
  //         setMinorOrder("descending")
  //         return -(a.minor - b.minor)
  //       }
  //     }
  //   })
  //   set_curr_audit(sortedAudits)
  // }


  const prevHandleClick = async () => {
    if (curr_page - 1 > 0) {
      const prev_page = curr_page - 1;
      set_query({ "page": prev_page, "limit": 10 });
      set_curr_page(prev_page);
    }
    // console.log("onclick prev : "+JSON.stringify(query) + " " + curr_page);
  };


  const nextHandleClick = async () => {
    const next_page = curr_page + 1;
    set_query({ "page": next_page, "limit": 10 });
    set_curr_page(next_page);
    // console.log("onclick next : "+JSON.stringify(query) + " " + curr_page);
  };


  const handlePageChangeDropDown = async (event) => {
    set_query({ "page": event.target.value, "limit": 10 });
    set_curr_page(event.target.value);
  };


  useEffect(() => {
    async function init_da_page() {
      let domain_audits = await server.get_domain_audits(project_name);
      let response = await server.get_line_chart_data(project_name);

      set_domain_audits(domain_audits);

      set_curr_audit(domain_audits[domain_audits.length - 1]);
      set_line_chart_data(response.chartData);
      // console.log("line chart data : " + JSON.stringify(response.chartData));
      // console.log("first render")
    }
    try {
      init_da_page();
      // console.log("init done")
    }
    catch (err) {
      console.log(err);
    }
  }, []);


  useEffect(() => {
    async function get_audit_pages() {
      // console.log("in get audit pages on query change")
      let response = await server.get_audit_pages(curr_audit.id, query);
      let pages = response.pages;
      let num_pages = response.total_pages;

      if (pages.length === 0) {
        const updated_page = curr_page - 1;
        set_query({ "page": updated_page, "limit": 10 });
        set_curr_page(updated_page);
      }
      set_total_pages(num_pages);
      set_audit_pages(pages);
    }
    try {
      if (query !== undefined) {
        // console.log("curr page state changed : " + JSON.stringify(query) + " "+curr_audit.id)
        get_audit_pages();
      }
    }
    catch (err) {
      console.log(err);
    }
  }, [curr_page]);


  useEffect(() => {
    async function update_da_page() {
      let results = await server.get_current_audit_results(curr_audit.id);
      let response = await server.get_audit_pages(curr_audit.id, query);
      // console.log("in update da page " + JSON.stringify(response))
      let pages = response.pages;
      let num_pages = response.total_pages;


      set_accessibility_score(results.accessibilityScore);
      set_fuddu_text(results.issueImpact.fuddu_text);
      set_issue_impact(results.issueImpact.impact);


      set_total_pages(num_pages);
      set_audit_pages(pages);
    }
    try {
      if (curr_audit.id) {
        // console.log("curr audit state changed : " + JSON.stringify(query) + " " + curr_audit.id);
        update_da_page();
      }
    }
    catch (err) {
      console.log(err);
    }
  }, [curr_audit]);


  return (
    <>
      <DocumentTitle title={"Analytics for " + project_name}>
        <div className={styles.analytics_container}>
          <div className={styles.breadcrumb_container}>{Breadcrumbs()}</div>
          <div className={styles.page}>
            <div className={styles.audit_stats}>
              <div className={styles.audit_stats_top}>
                <Stateless_AuditList name={project_name} domain_audits={domain_audits} set_curr_audit={set_curr_audit} handleDateClick={handleDateClick} handleUrlClick={handleUrlClick} handle_violation_click={handle_violation_click} accessibleDateTimeSort={dateTimeOrder !== "" ? dateTimeOrder : "other"} accessibleUrlSort={urlOrder !== "" ? urlOrder : "other"} accessibleViolationSort={vioOrder !== "" ? vioOrder : "other"} />
                <div className={styles.table_chart_wrapper}>
                  {/* <div role="group" aria-label="violations" className={styles.options_container}>
                  <button className={styles.toggle_button} onClick={() => setView("Chart View")} aria-pressed={view === "Chart View"}>Table view</button>
                  <button className={styles.toggle_button} onClick={() => setView("Table View")} aria-pressed={view === "Table View"}>Chart view</button>
                </div>
                {view === "Table View" && <ChartView issue_impact={issue_impact} accessibleBarChartText={accessibleBarChartText} />}
                {view === "Chart View" && <TableView issue_impact={issue_impact} />} */}
                  {/* <TableView issue_impact={issue_impact} /> */}
                  <ResponsiveContainer height={350} className={styles.linechart_container}>
                    <LineChart
                      data={line_chart_data}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                      aria-hidden="true">
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line type="monotone" dataKey="violations" stroke="#8884d8" activeDot={{ r: 8 }} />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <div className={styles.audit_stats_bot}>
                <Card className={styles.impact_table_container}>
                  <h3 className={styles.audit_list_heading}>Total issues in each page</h3>
                  <Table bordered size="lg" style={{ minWidth: "980px", width: "100%" }}>
                    <thead>
                      <tr>
                        <th>URL</th>
                        {/* <th onClick={() => handleImpactClick("Critical")} style={{ cursor: 'pointer', minWidth: "100px" }} aria-sort={criticalOrder !== "" ? criticalOrder : "none"}><span role="button" tabIndex={0}>
                          Critical <TiArrowUnsorted style={{ float: "right", marginTop: "2px" }} />
                        </span>
                        </th>
                        <th onClick={() => handleImpactClick("Serious")} style={{ cursor: 'pointer', minWidth: "100px" }} aria-sort={seriousOrder !== "" ? seriousOrder : "none"}><span role="button" tabIndex={0}>
                          Serious <TiArrowUnsorted style={{ float: "right", marginTop: "2px" }} />
                        </span>
                        </th>
                        <th onClick={() => handleImpactClick("Moderate")} style={{ cursor: 'pointer', minWidth: "100px" }} aria-sort={moderateOrder !== "" ? moderateOrder : "none"}><span role="button" tabIndex={0}>
                          Moderate <TiArrowUnsorted style={{ float: "right", marginTop: "2px" }} />
                        </span>
                        </th>
                        <th onClick={() => handleImpactClick("Minor")} style={{ cursor: 'pointer', minWidth: "100px" }} aria-sort={minorOrder !== "" ? minorOrder : "none"}><span role="button" tabIndex={0}>
                          Minor <TiArrowUnsorted style={{ float: "right", marginTop: "2px" }} />
                        </span>
                        </th> */}
                        <th style={{ minWidth: "90px" }} >Critical</th>
                        <th style={{ minWidth: "90px" }} >Serious</th>
                        <th style={{ minWidth: "90px" }} >Moderate</th>
                        <th style={{ minWidth: "90px" }} >Minor</th>
                      </tr>
                    </thead>
                    <tbody>
                      {audit_pages && audit_pages.map(page => {
                        if (page.PageId !== null)
                          return <tr key={page.PageId}>
                            <td>
                              <Link to={"/analytics" + "/pages/" + page.PageId}>
                                {page.url}
                              </Link>
                            </td>
                            <td>{page.critical}</td>
                            <td>{page.serious}</td>
                            <td>{page.moderate}</td>
                            <td>{page.minor}</td>
                          </tr>
                      })}
                    </tbody>
                  </Table>
                  <flex className={styles.page_control}>
                    <Button className={styles.prev_list_button} onClick={prevHandleClick}>Prev</Button>
                    <flex className={styles.page_control}>Page 
                    <select className={styles.dropdown} value={curr_page} onChange={handlePageChangeDropDown}>
                      {page_list.map((page) =>
                        <option value={page}>{page}</option>
                      )}
                    </select> of {total_pages}
                    </flex>
                    <Button className={styles.next_list_button} onClick={nextHandleClick}>Next</Button>
                  </flex>
                </Card>
              </div>
            </div>
            <div className={styles.domain_stats}>
              <div className={styles.domain_top}>
                <div className={styles.domain_heading_container}>
                  <h2 className={styles.health_heading}>Website Health</h2>
                  <h3 id="score-head">Current Accessibility Score</h3>
                </div>
                <div className={styles.speedometer_container} role="progressbar" aria-valuenow={accessibility_score} aria-labelledby="score-head" aria-min="0" aria-max="100" aria-valuetext={"Current accessibility score: " + accessibility_score + "%"}>
                  <CircularProgressbar value={accessibility_score} text={`${accessibility_score}%`} circleRatio={0.75} styles={buildStyles({
                    rotation: 1 / 2 + 1 / 8,
                    strokeLinecap: "butt",
                    trailColor: "#eee"
                  })}
                  />
                </div>
              </div>
              <CategoryTable issue_impact={issue_impact} severity_option={severity_option} order_option={order_option} stateful_severity={severity} stateful_severity_order={severity_order} handle_severity={handle_severity} handle_severity_order={handle_severity_order} />
            </div>
          </div>
        </div>
      </DocumentTitle>
    </>
  );
}


const Stateless_AuditList = ({ name, domain_audits, set_curr_audit, handleDateClick, handleUrlClick, handle_violation_click, accessibleDateTimeSort, accessibleUrlSort, accessibleViolationSort }) => {
  return (
    <Card className={styles.audit_list_container}>
      <div className={styles.audit_list_header}>
        <h2 className={styles.audit_list_heading}>Audits</h2>
      </div>
      <Table bordered>
        <thead>
          <tr>
            <th style={{ cursor: 'pointer', minWidth: "100px" }}>Audit Name </th>
            <th onClick={handleDateClick} style={{ cursor: 'pointer', minWidth: "100px" }} aria-sort={accessibleDateTimeSort}> <span role="button" tabIndex={0}>
              Date <TiArrowUnsorted style={{ float: "right", marginTop: "2px" }} />
            </span>
            </th>
            <th onClick={handleDateClick} style={{ cursor: 'pointer', minWidth: "100px" }} aria-sort={accessibleDateTimeSort}><span role="button" tabIndex={0}>
              Time <TiArrowUnsorted style={{ float: "right", marginTop: "2px" }} />
            </span>
            </th>
            <th onClick={handleUrlClick} style={{ cursor: 'pointer', minWidth: "100px" }} aria-sort={accessibleUrlSort}><span role="button" tabIndex={0}>
              URLs <TiArrowUnsorted style={{ float: "right", marginTop: "2px" }} />
            </span>
            </th>
            <th onClick={handle_violation_click} style={{ cursor: 'pointer', minWidth: "100px" }} aria-sort={accessibleViolationSort}><span role="button" tabIndex={0}>
              Violations <TiArrowUnsorted style={{ float: "right", marginTop: "2px" }} />
            </span>
            </th>
          </tr>

        </thead>
        <tbody>
          {domain_audits.map(domain_audit => {
            let date = new Date(domain_audit.createdAt);
            return (
              <tr style={{ cursor: "pointer" }} key={domain_audit.id} onClick={() => {
                let temp = domain_audits.filter(x => x.id === domain_audit.id)[0];
                set_curr_audit(temp)
              }}>
                <th>
                  {domain_audit.domain_name}
                  {/* <Link to={"/analytics" + "/audits/" + domain_audit.id}>{domain_audit.domain_name}</Link> */}
                </th>
                <td>{date.toLocaleDateString()}</td>
                <td>{date.toLocaleTimeString()}</td>
                <td style={{ textAlign: 'center' }}>{domain_audit.urls}</td>
                <td style={{ textAlign: 'center' }}>{domain_audit.violations}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </Card >
  )
}


const ChartView = ({ issue_impact, accessibleBarChartText }) => {
  return (
    <ResponsiveContainer width="100%" height={270}>
      <BarChart data={
        issue_impact}
        role="img"
        aria-label={accessibleBarChartText}
      >
        <XAxis dataKey="name" aria-hidden="true" />
        <YAxis aria-hidden="true" />
        <Tooltip />
        <Legend />
        <Bar dataKey="Violations" fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer>
  )
}


const TableView = ({ issue_impact }) => {
  return (
    <div className={styles.table_view_container}>
      <h2>All Violations</h2>
      <table className={styles.table_view}>
        <tr>
          <th>Critical</th>
          <th>Serious</th>
          <th>Moderate</th>
          <th>Minor</th>
        </tr>
        <tr>
          {
            issue_impact.map((impact) => {
              return <td>{impact.Violations}</td>
            })}
        </tr>
      </table>
    </div>
  )
}

const CategoryTable = ({ issue_impact, severity_option, order_option, stateful_severity, stateful_severity_order, handle_severity, handle_severity_order }) => {
  return (
    <div className={styles.category_container}>
      <div className={styles.filter_container}>
        <div className={styles.filter_wrapper}>
          <div className={styles.label_container}>
            <label id="filterlabel" className={styles.filter_label}>Sort By Severity:</label>
          </div>
          <select name="filter" style={{ textTransform: "capitalize" }} className={styles.category_dropdown} value={stateful_severity} onChange={(e) => { handle_severity(e.target.value) }} aria-labelledby="filterlabel">
            {severity_option.map((severity) => 
              <option value={severity} style={{ textTransform: "capitalize" }}>{severity}</option>
            )}
          </select>
        </div>
        <div className={styles.filter_wrapper}>
          <div className={styles.label_container}>
            <label id="filterlabel" className={styles.filter_label}>Order By:</label>
          </div>
          <select name="filter" style={{ textTransform: "capitalize" }} className={styles.category_dropdown} value={stateful_severity_order} onChange={(e) => { handle_severity_order(e.target.value) }} aria-labelledby="filterlabel">
            {order_option.map((order) =>
              <option value={order} style={{ textTransform: "capitalize" }}>{order}</option>
            )}
          </select>
        </div>
      </div>
      <table className={styles.category_table}>
        <thead>
          <tr>
            <th>Critical</th>
            <th>Serious</th>
            <th>Moderate</th>
            <th>Minor</th>
          </tr>
        </thead>
        <tbody>
          {
            issue_impact.map((impact) => {
              return <td>{impact.Violations}</td>
            })}
        </tbody>
      </table>
    </div>
  )
}


export default AnalyticsDomain;

// filter bar component
// filters audits
// get_audits_route -> filter and sort db queries.
// filter_bar( set_ )