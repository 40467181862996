import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';

const logout = (removeCookie , history) => {
  removeCookie('user' , {path : "/"});
  history.push("/");
}

const Header = ({removeCookie}) => {

  let history = useHistory();

  const handlePassClick = () => {
    history.push("/passwordchange")
  }

  const navStyle = {
    color: 'white',
    backgroundColor: '#5F2498',
    paddingRight: '20px',
    // backgroundColor: 'gold'
  }

  return (
    <header>
      <Navbar expand="sm" style={navStyle} aria-label="main">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto" defaultActiveKey='/home'>
            {/* <Login server={server} permissions={permissions}
                localLogin={(u, p) => localLogin(u, p)} logout={() => logout()} /> */}
            <Button variant="secondary" onClick={() => logout(removeCookie , history)} className="float-right">
              Log out
            </Button>
            <Button variant="secondary" onClick={handlePassClick} style={{marginLeft: "10px"}} className="float-right">
              Change Password
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  )
};

export default Header;
