import React, { useEffect, useState } from 'react'
import styles from "./UserPage.module.css"
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const UserPage = ({ server }) => {
  const [users, setUsers] = useState([])

  useEffect(() => {
    const getAllUsers = async () => {
      let userData = await server.getUsers()
      console.log(userData)
      setUsers(userData)
    }
    getAllUsers()
  }, [])

  return (
    <div className={styles.wrapper}>
      <h1>All Users</h1>
      <div className={styles.table_container}>
        <Table bordered size="lg" className={styles.user_table}>
          <thead>
            <tr>
              <th className={styles.th_border}>User</th>
              <th className={styles.th_border}>Name</th>
              <th className={styles.th_border}>Email</th>
              <th className={styles.th_border}>Verfied</th>
              <th className={styles.th_border}>Total Scans Ran</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => {
              return (
                <tr key={user.id} className={styles.th_border}>
                  <td>{user.user_name}</td>
                  <td>{user.firstname + " " + user.lastname}</td>
                  <td>{user.email}</td>
                  <td>{user.verified ? "Yes" : "No"}</td>
                  <td>{user.totalScans === 0 && "0"}{user.totalScans !== 0 && <Link to={"/admin/users/" + user.id}>{user.totalScans}</Link>} </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default UserPage