import styles from "./SidebarNav.module.css";
import 'font-awesome/css/font-awesome.min.css';
import logo from '../../assets/lera.svg';
import { Link } from 'react-router-dom';

export const SidebarNav = () => {

  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebarlogo}>
        <a href="/" style={{ textAlign: "center" }}>
          <img src={logo} alt={'Lera'} width={200} height={200} />
        </a>
      </div>
      <div className={styles.sidebarcontent} >

        <nav aria-label="Sidebar">
          <ul className={styles.sidebar_list}>
          {/* <li className={styles.sidebar_item}><Link to={"/"} >Dashboard</Link></li> */}
          <li className={styles.sidebar_item}><Link to={"/audits"} >Audits</Link></li>
          <li className={styles.sidebar_item}><Link to={"/analytics"} >Projects</Link></li>
          <li className={styles.sidebar_item}><a href="https://advancedbytez.com/support/" target="_blank" rel="noopener noreferrer" title="Opens in new tab">
            Support
          </a></li>
          </ul>
        </nav>
      </div>
    </div>
  );
}