import React from 'react'

const BillingHistory = () => {
  return (
    <div>
      <div className="mb-3">
        <h1>Billing History</h1>
  <label for="exampleFormControlInput1" className="form-label">TransactionId</label>
  <input type="transaction id" className="form-control" id="exampleFormControlInput1" placeholder="transaction id"/>
</div>
<div className="mb-3">
  <label for="exampleFormControlTextarea1" className="form-label">Name</label>
  <input type="transaction id" className="form-control" id="exampleFormControlInput1" placeholder="name"/>
</div>
<div className="mb-3">
  <label for="exampleFormControlTextarea1" className="form-label">Time</label>
  <input type="transaction id" className="form-control" id="exampleFormControlInput1" placeholder="hh:mm:ss"/>
</div>
<div className="mb-3">
  <label for="exampleFormControlTextarea1" className="form-label">Date</label>
  <input type="transaction id" className="form-control" id="exampleFormControlInput1" placeholder="dd/mm/yyyy"/>
</div>
<div className="mb-3">
  <label for="exampleFormControlTextarea1" className="form-label">Email</label>
  <input type="transaction id" className="form-control" id="exampleFormControlInput1" placeholder="ex-xyz@gmail.com"/>
</div>
    </div>
  )
}

export default BillingHistory
