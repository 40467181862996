class ServerAPI {

  constructor() {
    this.cache = {
      lastAuditList: null,
      lastAudit: null,
      lastDomain: null,
      lastPage: null,
    };
    const protocol = window.location.protocol;
    let port;
    if (process.env.REACT_APP_NODE_ENV === 'production')
      port = process.env.REACT_APP_PRODUCTION_PORT;
    else
      port = process.env.REACT_APP_DEVELOPMENT_API_PORT;
    const portString = (protocol === 'https:' || port === '80') ? '' : ':' + port;
    this.hostURL = protocol + '//' + window.location.hostname + portString;
  }

  request(method, path, parameters) {
    return new Promise((resolve, reject) => {
      const controller = new AbortController();
      // const timeout = setTimeout(() => controller.abort(), 3000);
      const fetchParams = {
        method: method,
        signal: controller.signal
      };
      if (parameters != null) {
        fetchParams.headers = { 'Content-Type': 'application/json' };
        if (parameters instanceof ArrayBuffer) {
          let isCompressed = false;
          const ar = new Uint8Array(parameters);
          if (ar[0] === 0x1F && ar[1] === 0x8B && ar[2] === 0x08)
            isCompressed = true;
          if (isCompressed)
            fetchParams.headers['Content-Encoding'] = 'gzip';
          fetchParams.body = parameters;
        } else if (typeof parameters === 'string') {
          fetchParams.body = parameters;
        } else {
          fetchParams.body = JSON.stringify(parameters);
        }
      }
      fetch(path, fetchParams)
        .then((response) => {
          if (!response.ok)
            reject(new Error(response.statusText));
          return response;
        })
        .then(data => data.json())
        .then((res) => {
          if (!res.success)
            reject(new Error(res.error));
          else
            resolve(res.data);
        })
        .catch(error => {
          reject(new Error("Error retrieving data: " + error.message));
          // if (error.name === 'AbortError')
          //   reject(new Error("Timeout when connecting to server (" +
          //     error.message + ")"));
          // else
          //   reject(new Error("Error retrieving data: " + error.message));
        })
        // .finally(() => clearTimeout(timeout));
    });
  }

  // changes made here
  async getAudits(query) {
    // if (this.cache.lastAuditList != null)
    //   return this.cache.lastAuditList;
    if (query === undefined || query === "") {
      const auditList = await this.request('GET', '/api/audits');
      return auditList;
    }
    // console.log(query);
    const auditList = await this.request('GET', "/api/audits"+query);
    // this.cache.lastAuditList = auditList;
    return auditList;
  }

  // Admin Panel
  async getUserAudits(userId) {
    const userAudits = await this.request('GET', "/api/audits/user/" + userId)
    return userAudits
  }

  // Admin Panel
  async getAllAudits() {
    const allAudits = await this.request("GET", "/api/audits/get/all")
    return allAudits
  }

  async getUserCount() {
    const userCount = await this.request('GET', '/api/users/get/count')
    return userCount
  }

  async getAuditCount() {
    const auditCount = await this.request('GET','/api/audits/get/count')
    return auditCount
  }

  async get_audits_filtered(query, filter) {
    if (query === undefined || query === "") {
      const auditList = await this.request('GET', '/api/audits/filtered', filter);
      return auditList;
    }
    // console.log(query);
    const auditList = await this.request('GET', "/api/audits/filtered"+query, filter);
    // this.cache.lastAuditList = auditList;
    return auditList;
  }

  async get_audit_pages_filtered(curr_audit_id, query, filter) {
    if (query === undefined || query === "") {
      query = { "page": 1, "limit": 10 };
    }
    const filtered_audit_pages = await this.request('POST', `/api/domains/audits/pages/filtered`, { curr_audit_id, query, filter });
    return filtered_audit_pages;
  }

  async get_line_chart_data(domain_name) {
    let data = { 'domain_name': domain_name };
    const line_chart_data = await this.request('POST', `/api/domains/audits/linechart`, data);
    return line_chart_data;
  }

  async get_current_audit_results(curr_audit_id) {
    let data = { 'curr_audit_id': curr_audit_id };
    const auditResults = await this.request('POST', `/api/domains/audits`, data);
    return auditResults;
  }

  async get_audit_pages(curr_audit_id, query) {
    // let data = {'curr_audit_id': curr_audit_id, 'query': query}; // why aint this fucker sending the id to the backend??????
    // console.log(data)
    if (query === undefined || query === "") {
      query = { "page": 1, "limit": 10 };
    }
    const auditPages = await this.request('POST', `/api/domains/audits/pages`, { curr_audit_id, query });
    return auditPages;
  }

  async get_domain_audits(domain_name) {
    let data = { 'domain_name': domain_name }
    const domainAudits = await this.request('POST', `/api/domains/`, data);
    return domainAudits;
  }

  async get_domain_audits_filtered(domain_name, filter) {
    // let data = { 'domain_name': domain_name }
    const filtered_domain_audits = await this.request('POST', `/api/domains/filter`, { domain_name, filter });
    return filtered_domain_audits;
  }
  
  // App
  async localLogin(username, password) {
    const user = await this.request('POST', '/api/app/login', { username, password });
    this.cache = {
      lastAuditList: null,
      lastAudit: null,
      lastDomain: null,
      lastPage: null,
    };
    return user;
  }
  samlLogin() {
    window.location.href = this.hostURL + '/api/app/login/saml';
  }
  async logout() {
    await this.request('POST', '/api/app/logout');
    this.cache = {
      lastAuditList: null,
      lastAudit: null,
      lastDomain: null,
      lastPage: null,
    };
  }

  async signup(user_obj) {
    const user = await this.request('POST', '/api/app/signup', { user_obj });
    return user;
  }

  async verifyOTP(otp, user) {
    const result = await this.request('POST', '/api/app/verifyOTP', { otp, user });
    return result;
  }

  // Audit
  getAuditStatus(auditId) {
    return this.request('GET', `/api/audits/${auditId}/status`);
  }

  get_running_audits() {
    return this.request('GET', `/api/audits/status`);
  }

  startAudit(params) {
    this.cache.lastAuditList = null;
    return this.request('POST', '/api/audits/start', params);
  }
  stopAudit(auditId) {
    return this.request('POST', `/api/audits/${auditId}/stop`);
  }

  async getAudit(auditId) {
    if (this.cache.lastAudit != null && this.cache.lastAudit.id === auditId)
      return this.cache.lastAudit;
    const audit = await this.request('GET', `/api/audits/${auditId}`);
    this.cache.lastAudit = audit;
    return audit;
  }
  async removeAudit(auditId) {
    this.cache.lastAuditList = null;
    const res = await this.request('DELETE', `/api/audits/${auditId}`);
    return res;
  }
  exportAudit(auditId) {
    window.location.href = this.hostURL + `/api/audits/${auditId}/export`;
  }
  async importAudit(data) {
    this.cache.lastAuditList = null;
    await this.request('POST', `/api/audits/import`, data);
  }
  async getDomain(domainId) {
    if (this.cache.lastDomain != null && this.cache.lastDomain.id === domainId)
      return this.cache.lastDomain;
    const domain = await this.request('GET', `/api/domains/${domainId}`);
    this.cache.lastDomain = domain;
    return domain;
  }
  /*getDomainPages(domainId) {
    return this.request('GET', `/api/domains/${domainId}/pages`);
  }*/
  async getPage(pageId) {
    if (this.cache.lastPage != null && this.cache.lastPage.id === pageId)
      return this.cache.lastPage;
    const page = await this.request('GET', `/api/pages/${pageId}`);
    this.cache.lastPage = page;
    return page;
  }

  getUsers() {
    return this.request('GET', '/api/users/');
  }
  createUser(user) {
    return this.request('POST', `/api/users/`, user);
  }
  getUser(userId) {
    return this.request('GET', `/api/users/${userId}`);
  }
  removeUser(userId) {
    return this.request('DELETE', `/api/users/${userId}`);
  }
  updateUser(user) {
    return this.request('PUT', `/api/users/${user.id}`, user);
  }
  async changePassword(user) {
    return await this.request('PUT', `/api/users/`, user);
  }
  addUserGroup(userId, groupId) {
    return this.request('PUT', `/api/users/${userId}/groups/${groupId}`);
  }
  removeUserGroup(userId, groupId) {
    return this.request('DELETE', `/api/users/${userId}/groups/${groupId}`);
  }
  getCurrentUser() {
    return this.request('GET', '/api/users/current');
  }

  getGroups() {
    return this.request('GET', '/api/groups/');
  }
  createGroup(group) {
    return this.request('POST', `/api/groups/`, group);
  }
  getGroup(groupId) {
    return this.request('GET', `/api/groups/${groupId}`);
  }
  // Added to get users of a specific group.
  getGroupUsers(groupId) {
    return this.request('GET', `/api/groups/${groupId}/users`);
  }
  removeGroup(groupId) {
    return this.request('DELETE', `/api/groups/${groupId}`);
  }
  updateGroup(group) {
    return this.request('PUT', `/api/groups/${group.id}`, group);
  }
  addGroupUser(groupId, userId) {
    return this.request('PUT', `/api/groups/${groupId}/users/${userId}`);
  }
  removeGroupUser(groupId, userId) {
    return this.request('DELETE', `/api/groups/${groupId}/users/${userId}`);
  }

  getAnalytics() {
    return this.request('GET', `/api/analytics/`);
  }

  getAnalyticsfromURL(analyticsURL) {
    return this.request('GET', `/api/analytics/${analyticsURL}`);
  }

  get_teams() {
    return this.request('GET', `/api/teams`);
  }

  get_team(team_id) {
    return this.request('GET', `/api/teams/${team_id}`);
  }

  modify_team(team_obj, team_id) {
    return this.request('PUT', `/api/teams/${team_id}`, team_obj);
  }

  create_team(team) {
    return this.request('POST', `/api/teams`, team);
  }

  remove_team(team_id) {
    return this.request('DELETE', `/api/teams/${team_id}`);
  }

  get_users(team_id) {
    return this.request('GET', `/api/teams/${team_id}/users`);
  }

  add_user_to_team(user_email, team_id) {
    return this.request('POST', `/api/teams/${team_id}/users`, user_email);
  }

  remove_user_from_team(team_id, user_team_id) {
    return this.request('DELETE', `/api/teams/${team_id}/users/${user_team_id}`);
  }

  async get_checkout_bronze() {
    return this.request('POST', `/api/payments/payment-checkout/bronze`);
  }


  async get_page_from_id(page_id) {
    return this.request('GET', `/api/pages/${page_id}`);
    // return [];
  }

  async get_domains() {
    return this.request('GET', '/api/domains/');
  }
}

export default ServerAPI;
