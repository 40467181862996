import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ServerAPI from "../../ServerAPI";
import styles from "./Payments.module.css";

const Payments = ({server}) => {
  const cardContent = [
    {
      title: "Gold",
      desc: "Gold plans cover about 80 percent",
      imgUrl:
        "https://img.freepik.com/free-vector/realistic-credit-card-design_23-2149126088.jpg?w=2000",
    },
    {
      title: "Silver",
      desc: "Silver plans cover about 70 percent",
      imgUrl:
        "https://img.freepik.com/free-vector/realistic-credit-card-design_23-2149126088.jpg?w=2000",
    },
    {
      title: "Bronze",
      desc: "Bronze plans cover about 60 percent",
      imgUrl:
        "https://img.freepik.com/free-vector/realistic-credit-card-design_23-2149126088.jpg?w=2000",
    },
  ];

  const handleOnClick = async (title) => {
    let session;
    switch (title) {
      case "Bronze":
        session = await server.get_checkout_bronze();
        // console.log(session.url);
        window.open(session.url);
        break;
      default:
        console.log(title);
        break;
    }
  };

  return (
    <div className="container">
      <h3>Payment</h3>

      <Row xs={1} md={3} className="g-3">
        {cardContent.map((element) => {
          return (
            <Col>
              <Card
                className={styles.cardButton}
                onClick={() => handleOnClick(element.title)}
              >
                <Card.Img variant="top" src={element.imgUrl} />
                <Card.Body>
                  <Card.Title>{element.title}</Card.Title>
                  <Card.Text>{element.desc}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

export default Payments;
