import React from 'react';
import Button from 'react-bootstrap/Button';
import "./AuditPage.css"
import AuditList from '../AuditList/AuditList';
import DocumentTitle from "react-document-title";
import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

function Breadcrumbs() {
    let curr_url = window.location.href;
    return (
        <Breadcrumb>
            {/* <Breadcrumb.Item href={curr_url + "/.."}><span className='bc_item'>Dashboard</span></Breadcrumb.Item> */}
            <Breadcrumb.Item active><span className='active_bc_item'>Audits</span></Breadcrumb.Item>
        </Breadcrumb>
    );
}

export const AuditPage = ({ server }) => {
    return (
        <DocumentTitle title="audits - Lera cloud">
            <div>
                <div className='breadcrumb_container'>{Breadcrumbs()}</div>
                <h2 className="audit_heading">Audits</h2>
                <div className='audit_parent'>
                    <div className='left_box'>
                        <div className='audit_options_container'>
                            <Link to='/audits/create'>
                                <Button role="presentation" tabIndex="0" className='btn btn-primary button_spacing butOne'>Begin Scan</Button>
                            </Link>
                            <Link to='#'>
                                <Button role="presentation" tabIndex="0" variant='secondary' className='button_spacing'>Import Audit</Button>
                            </Link>
                        </div>
                    </div>
                    <div className='right_box'>
                        <AuditList server={server} />
                    </div>
                </div>
            </div>
        </DocumentTitle>
    )
}
