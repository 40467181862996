import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Link, useParams } from 'react-router-dom';

let interval_id = null;

const AuditStatus = ({ server }) => {
  const { auditId } = useParams();
  const [error, set_error] = useState(null);
  const [status, set_status] = useState({});
  const [running, set_running] = useState(false);
  const [stop, set_stop] = useState(false);

  const checkDelay = 2000;

  const stopAudit = () => {
    set_stop(true);
    server.stopAudit(auditId).then(
      (data) => {
        set_running(false);
      },
      (error) => {
        set_error(error.message);
      }
    );
  };

  const checkStatus = () => {
    server.getAuditStatus(auditId).then(
      (data) => {
        console.log(data.running);
        if (!data.running && interval_id != null) {
          clearInterval(interval_id);
          interval_id = null;
        }
        document.title = 'Audit Status: ' + data.initialDomainName;
        set_status(data);
        set_running(data.running);
      },
      (error) => {
        set_error(error.message);
        set_status(null);
      }
    );
  };

  useEffect(() => {
    interval_id = setInterval(() => checkStatus(), checkDelay);

    return () => {
      if (interval_id != null) {
        clearInterval(interval_id);
        interval_id = null;
      }
    };
  }, []);

  useEffect(() => {
    if (error) document.querySelector('.alert').focus();
  }, [error]);

  return (
    <div>
      <h1>
        {status && status.initialDomainName
          ? status.initialDomainName
          : 'Audit Status'}
      </h1>
      <Alert
        show={error != null}
        variant='danger'
        dismissible
        onClose={() => set_error(null)}
        tabIndex='0'
      >
        {error}
      </Alert>
      {status && status.running && (
        <p className='m-5'>
          <Button
            variant='danger'
            size='sm'
            onClick={(e) => stopAudit()}
            disabled={!running || stop}
          >
            Stop the audit
          </Button>
        </p>
      )}
      {status && status.running !== undefined && (
        <section role="region" aria-label="audit status" aria-live="polite">
          <h2>Status</h2>
          <Table bordered size='sm' className='data'>
            <tbody>
              <tr>
                <th>Running</th>
                <td>{status.running ? 'Yes' : 'No'}</td>
              </tr>
              <tr>
                <th>Checked URLs</th>
                <td>{status.nbCheckedURLs}</td>
              </tr>
              <tr>
                <th>URLs to check</th>
                <td>{status.nbURLsToCheck} (more might be added later)</td>
              </tr>
              <tr>
                <th>Violations found</th>
                <td>{status.nbViolations}</td>
              </tr>
              <tr>
                <th>Scan errors</th>
                <td>{status.nbScanErrors}</td>
              </tr>
            </tbody>
          </Table>
        </section>
      )}
      <section>
        <h2>Results</h2>
        <Link to={'/audits/' + auditId}>Audit results</Link>
      </section>
    </div>
  );
};

export default AuditStatus;
