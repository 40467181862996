import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/esm/Card';
import styles from './AuditList.module.css';
import { TiArrowUnsorted } from "react-icons/ti"

const AuditList = ({ server }) => {
  let [audits, set_audits] = useState([]);
  const [selected, setSelected] = useState("new-audits");
  const [dateTimeOrder, setDateTimeOrder] = useState("none");
  let [runningAudit, setRunningAudit] = useState([])
  const [urlOrder, setUrlOrder] = useState("none");
  const [vioOrder, setVioOrder] = useState("none")
  const [curr_page, setCurrPage] = useState(1);
  const [query, setQuery] = useState(`?page=${curr_page}&limit=10`);
  const [total_pages, set_total_pages] = useState(1);
  const page_list = new Array(total_pages).fill(1).map((_, i) => i + 1);

  const delete_audit = async (audit_id) => {
    try {
      const res = await server.removeAudit(audit_id);
      console.log(res);
      audits = audits.filter((aud) => {
        return aud.id !== audit_id;
      });
      set_audits(audits);
    } catch (err) {
      console.log(err);
    }
  };

  const export_audit = (audit_id) => {
    server.exportAudit(audit_id);
  }

  const handleSortClick = async() => {
    setUrlOrder("none");
    setVioOrder("none");
    let sortedAudits;
    if (selected === "new-audits" || selected === "") {
      setSelected("old-audits")
      setDateTimeOrder("ascending");
      sortedAudits = await server.get_audits_filtered( {'param':'createdAt' , 'order':'ASC'});
    }
    else {
      setSelected("new-audits")
      setDateTimeOrder("descending");
      sortedAudits = await server.get_audits_filtered( {'param':'createdAt' , 'order':'DESC'});
    }
    
    set_audits(sortedAudits);
  };

  const handleUrlClick = () => {
    setDateTimeOrder("none");
    setVioOrder("none");
    // console.log("here")
    let sortedAudits = JSON.parse(JSON.stringify(audits))
    sortedAudits.sort((a, b) => {
      if (urlOrder === "none" || urlOrder === "descending") {
        setUrlOrder("ascending")
        return (a.pages - b.pages)
      }
      else {
        setUrlOrder("descending")
        return -(a.pages - b.pages)
      }
    })

    set_audits(sortedAudits)
  }

  const handleVioClick = () => {
    setDateTimeOrder("none");
    setUrlOrder("none");
    let sortedAudits = JSON.parse(JSON.stringify(audits))
    sortedAudits.sort((a, b) => {
      if (vioOrder === "none" || vioOrder === "descending") {
        setVioOrder("ascending")
        return (a.nodes - b.nodes)
      }
      else {
        setVioOrder("descending")
        return -(a.nodes - b.nodes)
      }
    })

    set_audits(sortedAudits)
  }
    
  const prevHandleClick = async () => {
    if (curr_page - 1 > 0) {
      const prev_page = curr_page - 1;
      setQuery(`?page=${prev_page}&limit=10`);
      setCurrPage(prev_page);
    }
  };

  const nextHandleClick = async () => {
    const next_page = curr_page + 1;
    setQuery(`?page=${next_page}&limit=10`);
    setCurrPage(next_page);
  };

  const handlePageChangeDropDown = async (event) => {
    setQuery(`?page=${event.target.value}&limit=10`);
    setCurrPage(event.target.value);  
  };

  useEffect(async () => {
    const get_audits = async () => {
      let response = await server.getAudits(query);
      let audits = response.audits;
      console.log(audits)
      let num_pages = response.total_pages;
      return { audits, num_pages };
    };
    get_audits().then(res => {
      let audits = res.audits;
      let num_pages = res.num_pages;
      if (audits.length !== 0) {
        set_audits(audits);
      }
      if (num_pages !== 0) {
        set_total_pages(num_pages);
      }
    }).catch(e => console.log(e));

    const get_running_audits = async () => {
      let running_audits = await server.get_running_audits();
      return running_audits;
    }

    let running_audits = await get_running_audits();
    setRunningAudit(running_audits)
  }, [])

  useEffect(async () => {
    const get_audits = async () => {
      let response = await server.getAudits(query);
      let audits = response.audits;
      let num_pages = response.total_pages;
      return { audits, num_pages };
    };

    const get_running_audits = async () => {
      let running_audits = await server.get_running_audits();
      return running_audits;
    }

    get_audits().then(res => {
      let audits = res.audits;
      let num_pages = res.num_pages;
      if (audits.length === 0) {
        const updated_page = curr_page - 1;
        setQuery(`?page=${updated_page}&limit=10`);
        setCurrPage(updated_page);
      }
      set_audits(audits);
      set_total_pages(num_pages);
    }).catch(e => console.log(e));

    let running_audits = await get_running_audits();
    setRunningAudit(running_audits)
  }, [curr_page])

  return (
    <Card className={styles.audit_list_container}>
      <Table bordered size="md">
        <thead>
          <tr>
            <th>Audit Name</th>
            <th onClick={handleSortClick} style={{ cursor: 'pointer', minWidth: "100px" }} aria-sort={dateTimeOrder}><span role="button" tabIndex={0}>
              Date <TiArrowUnsorted style={{ float: "right", marginTop: "2px" }} />
            </span>
            </th>
            <th onClick={handleSortClick} style={{ cursor: 'pointer', minWidth: "100px" }} aria-sort={dateTimeOrder}><span role="button" tabIndex={0}>
              Time <TiArrowUnsorted style={{ float: "right", marginTop: "2px" }} />
            </span>
            </th>
            <th>Status</th>
            <th onClick={handleUrlClick} style={{ cursor: 'pointer', minWidth: "100px" }} aria-sort={urlOrder}><span role="button" tabIndex={0}>
              URLs <TiArrowUnsorted style={{ float: "right", marginTop: "2px" }} />
            </span>
            </th>
            <th onClick={handleVioClick} style={{ cursor: 'pointer', minWidth: "100px" }} aria-sort={vioOrder}><span role="button" tabIndex={0}>
              Violations <TiArrowUnsorted style={{ float: "right", marginTop: "2px" }} />
            </span>
            </th>
            <th style={{ minWidth: "105px" }}>Options</th>
          </tr>

        </thead>
        <tbody>
          {audits.map(audit => {
            let date = new Date(audit.createdAt);
            let setColor = runningAudit.includes(audit.id) ? "blue" : !audit.complete ? "red" : "green";
            let isRunning = runningAudit.includes(audit.id)
            return (
              <tr key={audit.id} style={{ color: setColor }}>
                <td style={{ textAlign: 'center' }}>
                  <Link to={"/analytics/" + audit.domain_name} aria-label={"Check analytics for " + audit.domain_name}>{audit.domain_name}</Link>
                </td>
                {/* Stopped audits aren't complete. */}
                <td>{date.toLocaleDateString()}</td>
                <td>{date.toLocaleTimeString()}</td>
                <td>
                  {isRunning && <Link to={"/audits/" + audit.id + "/status"}>Running</Link>}
                  {!isRunning && !audit.complete && <span>Failed</span>}
                  {!isRunning && audit.complete && <span>Completed</span>}
                </td>
                <td style={{ textAlign: 'center' }}>{audit.pages}</td>
                <td style={{ textAlign: 'center' }}>{audit.nodes}</td>
                <td style={{ textAlign: 'center' }}>
                  <Button onClick={() => { delete_audit(audit.id); }} aria-label={"Delete audit " + audit.domain_name}><i className="fa fa-trash"></i></Button>{' '}
                  <Button onClick={() => { export_audit(audit.id); }} aria-label={"Export audit " + audit.domain_name}><i className="fa fa-download"></i></Button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      <flex className={styles.page_control}>
        <Button className={styles.prev_list_button} onClick={prevHandleClick}>Prev</Button>
        <flex className={styles.page_control}>Page
          <select className={styles.dropdown} value={curr_page} onChange={handlePageChangeDropDown}>
            {page_list.map((page) =>
              <option value={page}>{page}</option>
            )}
          </select> of {total_pages}
        </flex>
        <Button className={styles.next_list_button} onClick={nextHandleClick}>Next</Button>
      </flex>
    </Card>
  )
}

export default AuditList;
