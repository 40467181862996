import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Table } from 'react-bootstrap'
import styles from "./UserAudit.module.css"
import { Link } from 'react-router-dom'

export const UserAudit = ({ server }) => {
    const { userId } = useParams()
    const [user, setUser] = useState([])
    const [userAudits, setUserAudits] = useState([])
    const [total, setTotal] = useState(0)

    useEffect(() => {
        const getUserData = async () => {
            let userData = await server.getUser(userId)
            setUser(userData)
            let userAudit = await server.getUserAudits(userId)
            setUserAudits(userAudit.rows)
            setTotal(userAudit.count)
        }
        getUserData()
        console.log(userAudits)
    }, [])
    return (
        <div className={styles.wrapper}>
            <h1>Scans for {user.user_name}</h1>
            <div className={styles.table_container}>
                <Table bordered size="lg" className={styles.user_table}>
                    <thead>
                        <tr>
                            <th className={styles.th_border}>URL</th>
                            <th className={styles.th_border}>Date</th>
                            <th className={styles.th_border}>Time</th>
                            <th className={styles.th_border}>Standard</th>
                            <th className={styles.th_border}>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userAudits.map(audit => {
                            let date = new Date(audit.createdAt);
                            return (
                                <tr key={audit.id}>
                                    <td style={{ textAlign: 'center' }}>
                                        {audit.domain_name}
                                    </td>
                                    {/* Stopped audits aren't complete. */}
                                    <td>{date.toLocaleDateString()}</td>
                                    <td>{date.toLocaleTimeString()}</td>
                                    <td>{audit.standard}</td>
                                    <td style={{ color: `${audit.complete ? "green" : "red"}` }}>{(audit.complete) && "Complete"}{(!audit.complete) && "Failed"}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}
