import React from "react";
import style from "./SkipLink.module.css";

const SkipLink=React.forwardRef((props,ref) => {
    
    const handleClick=(e) => {
ref.current.focus();
    };
    return (
<a href="#" className={style.skip_link} ref={ref} onClick={handleClick}>{props.text}</a>
);

});




export default SkipLink;