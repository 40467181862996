import React from 'react'
import styles from "./Change.module.css"
import { useState } from 'react'
import { Error } from "../../components/Error";
import DocumentTitle from "react-document-title";
import { useHistory } from 'react-router-dom';

const ChangePassword = ({ server }) => {
    const [password, setPassword] = useState("")
    const [show, setShow] = useState(false);
    const [error, setError] = useState(null);

    let history = useHistory();

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const params = {
                password: password
            }
            await server.changePassword(params)
            history.push("/")
        }
        catch (error) {
            console.log(error)
            setError(error.message)
            setShow(true)
        }
    }

    return (
        <DocumentTitle title="Change Password">
            <>
                <div className={styles.audit_form_container}>
                    {error != null &&
                        <Error error_message={error} setShow={setShow} show={show} />
                    }
                    <div className={styles.audit_form_box}>
                        <form onSubmit={async (e) => handleSubmit(e)} style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px" }}>
                            {/* <p style={{alignSelf:'flex-start'}}>Required fields are marked by * symbol (Asterisk)</p> */}
                            <div className={styles.form_group}>
                                <label htmlFor="password">New Password</label>
                                <input type="password" placeholder="Password" name="password" value={password} className={styles.input_field} onChange={(e) => setPassword(e.target.value)} required/>
                            </div>
                            <div className={styles.btn_container}>
                                <button type="submit" className={styles.audit_button} >Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        </DocumentTitle>
    )
}

export default ChangePassword