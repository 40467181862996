import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Link, useHistory } from "react-router-dom";
import { Error } from "../Error";
import styles from './SignUpForm.module.css';
import DocumentTitle from "react-document-title";

const handleSubmit = async (event, setCookie, server, user_obj, setError, setShow, history) => {
    event.preventDefault();
    try {
        let user = await server.signup(user_obj);
        if (user === null) {
            setError("We seem to be having server issues");
        }
        else {
            setCookie('user', user, { path: "/" });
        }
        history.push("/otp");
    } catch (error) {
        console.log(error);
        setError(error.message);
        setShow(true);
    }
}


export const SignUpForm = ({ setCookie, server }) => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password_2, setPassword_2] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [username, setUsername] = useState("");

    let history = useHistory();
    let user_obj;

    // For error component only
    const [error, setError] = useState(null);
    const [show, setShow] = useState(false);

    function validateForm() {
        return email.length > 0 &&
            password.length > 0 &&
            firstname.length > 0 &&
            lastname.length > 0 &&
            username.length > 0 &&
            password === password_2;
    }

    return (
        <DocumentTitle title="Sign up - lera cloud">
        <div className={styles.signup_container}>
            {error != null &&
                <Error error_message={error} setShow={setShow} show={show} />
            }
            <div className={styles.signup_box}>
                <div className={styles.signup_text}>
                    SIGN UP
                </div>
                <form onSubmit={(e) => {
                    user_obj = {
                        email: email,
                        user_name: username,
                        firstname: firstname ? firstname : "",
                        lastname: lastname ? lastname : "",
                        password_hash: password
                    };
                    handleSubmit(e, setCookie, server, user_obj, setError, setShow, history)
                }}>
                    <input type="email" placeholder="Email" name="email" value={email} className={styles.input_field} onChange={(e) => setEmail(e.target.value)} />
                    <input type="text" placeholder="Username" name="username" value={username} className={styles.input_field} onChange={(e) => setUsername(e.target.value)} />
                    <input type="text" placeholder="First Name" name="firstname" value={firstname} className={styles.input_field} onChange={(e) => setFirstname(e.target.value)} />
                    <input type="text" placeholder="Last Name" name="lastname" value={lastname} className={styles.input_field} onChange={(e) => setLastname(e.target.value)} />
                    <input type="password" placeholder="Password" name="password" value={password} className={styles.input_field} onChange={(e) => setPassword(e.target.value)} />
                    <input type="password" placeholder="Re-enter Password" name="password_2" value={password_2} className={styles.input_field} onChange={(e) => setPassword_2(e.target.value)} />
                    <div className={styles.btn_container}>
                        <button type="submit" className={styles.signup_button} disabled={!validateForm()} >Sign Up</button>
                    </div>
                </form>
                <div className={styles.signin_redirect_wrapper}>
                    Already have an account? <Link to="/sign-in">Sign In</Link>
                </div>
            </div>
        </div>
        </DocumentTitle>
    );

}