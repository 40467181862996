import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import styles from "./AdminAuditPage.module.css"


const AdminAuditPage = ({ server }) => {
  const [audits, setAudits] = useState([])
  useEffect(() => {
    const getAllAudits = async () => {
      const data = await server.getAllAudits()
      setAudits(data)
    }
    getAllAudits()
  }, [])

  return (
    <div className={styles.wrapper}>
      <h1>All Audits</h1>
      <div className={styles.table_container}>
        <Table bordered size="lg" className={styles.user_table}>
          <thead>
            <tr>
              <th className={styles.th_border}>User</th>
              <th className={styles.th_border}>URL</th>
              <th className={styles.th_border}>Date</th>
              <th className={styles.th_border}>Time</th>
              <th className={styles.th_border}>Standard</th>
              <th className={styles.th_border}>Status</th>
            </tr>
          </thead>
          <tbody>
            {audits.map(audit => {
              let date = new Date(audit.createdAt);
              return (
                <tr key={audit.id}>
                  <td>{audit.User.user_name}</td>
                  <td style={{ textAlign: 'center' }}>
                    {audit.domain_name}
                  </td>
                  {/* Stopped audits aren't complete. */}
                  <td>{date.toLocaleDateString()}</td>
                  <td>{date.toLocaleTimeString()}</td>
                  <td>{audit.standard}</td>
                  <td style={{ color: `${audit.complete ? "green" : "red"}` }}>{(audit.complete) && "Complete"}{(!audit.complete) && "Failed"}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default AdminAuditPage