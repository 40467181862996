import { useState } from "react"
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import { Error } from "../Error";
import styles from './OtpForm.module.css';
import DocumentTitle from "react-document-title";
import { Link } from "react-router-dom";



const handleSubmit = async (e, otp, server, cookies, setCookie, removeCookie, history, setError, setShow) => {
    // When verified, will return user. Else null
    e.preventDefault();
    try {
        const user = await server.verifyOTP(otp, cookies.user);
        setCookie('user', user, { path: "/" });
        history.push("/");
    } catch (error) {
        setError(error.message);
        setShow(true);
        removeCookie('user', { path: "/" });
    }
}

export const OtpForm = ({ cookies, setCookie, removeCookie, server }) => {
    const [otp, changeOTP] = useState("");

    const validateForm = () => {
        return otp.length == 6
    }
    let history = useHistory();
    const [error, setError] = useState(null);
    const [show, setShow] = useState(false);


    return (
        <DocumentTitle title="Verify by OTP - Lera cloud">
            <>
                <Error error_message={error} setShow={setShow} show={show} />

                <div className={styles.otp_container}>
                    <div className={styles.otp_box}>
                        <div className={styles.login_text}>
                            Complete verification
                        </div>
                        <form onSubmit={(e) => handleSubmit(e, otp, server, cookies, setCookie, removeCookie, history, setError, setShow)} className={styles.form_container}>
                            <input type="text" placeholder="Enter OTP" name="otp" value={otp} className={styles.input_field} onChange={(e) => changeOTP(e.target.value)} />

                            <div className={styles.btn_container}>
                                <button type="submit" className={styles.signin_button} disabled={!validateForm()} >Submit</button>
                            </div>
                            <div className={styles.signup_redirect_wrapper}>
                                Check your email for OTP
                            </div>
                        </form>
                    </div>
                </div>

                {/* <div className={styles.otpForm_box}>
                    <h2>Otp Form</h2>
                    <div style={{ height: "30px" }}></div>
                    <Form onSubmit={(e) => handleSubmit(e, otp, server, cookies, setCookie, removeCookie, history, setError, setShow)}>
                        <Form.Group className={styles.user_box} >
                            <Form.Control className={styles.input}
                                // type="input"
                                value={otp}
                                onChange={(e) => { changeOTP(e.target.value); console.log(otp) }}
                            />
                            <Form.Label className={styles.label}>OTP</Form.Label>

                        </Form.Group>
                        <Button block size="lg" type="submit" disabled={!validateForm()}>
                            Submit Otp
                        </Button>

                        <span>Please check your email for otp</span>

                    </Form>
                </div> */}
            </>
        </DocumentTitle>
    )
}