import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./SignInForm.module.css";
import { Error } from "../Error";
import styles from './SignInForm.module.css';
import { Link } from "react-router-dom";
import DocumentTitle from "react-document-title";

export const SignInForm = ({ setCookie, server }) => {

    let history = useHistory();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const [show, setShow] = useState(false);

    const handleSubmit = async (event, setCookie, server, history, [username, password, setError, setShow]) => {
        event.preventDefault();
        setShow(false);
        try {
            let user = await server.localLogin(username, password);
            setCookie('user', user, { path: "/" });
            history.push("/");
        } catch (error) {
            console.log(error.message);
            setError(error.message);
            setShow(true);
        }
    }

    function validateForm() {
        return username.length > 0 && password.length > 0;
    }

    return (
        <DocumentTitle title="Sign in - Lera cloud">
        <>
            {error != null &&
                <Error error_message={error} setShow={setShow} show={show} />
            }
            <div className={styles.login_container}>
                <div className={styles.login_box}>
                    <div className={styles.login_text}>
                        SIGN IN
                    </div>
                    <form onSubmit={async (e) => handleSubmit(e, setCookie, server, history, [username, password, setError, setShow])} className={styles.form_container}>
                        <input type="text" placeholder="Username" name="username" value={username} className={styles.input_field} onChange={(e) => setUsername(e.target.value)} />
                        <input type="password" placeholder="Password" name="password" value={password} className={styles.input_field} onChange={(e) => setPassword(e.target.value)} />
                        <div className={styles.btn_container}>
                            <button type="submit" className={styles.signin_button} disabled={!validateForm()} >Sign In</button>
                        </div>
                    </form>
                    <div className={styles.signup_redirect_wrapper}>
                        Don't have an account? <Link to="/sign-up">Sign Up</Link>
                    </div>
                </div>
            </div>
        </>
        </DocumentTitle>
    );

}