import Card from "react-bootstrap/Card";
import styles from "./Dashboard.module.css";
import "font-awesome/css/font-awesome.min.css";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import DocumentTitle from "react-document-title";
import Breadcrumb from "react-bootstrap/Breadcrumb";

function Breadcrumbs() {
  let curr_url = window.location.href;
  return (
    <Breadcrumb>
      {/* <Breadcrumb.Item href={curr_url + "/.."}> <span className='bc_item'>Dashboard</span></Breadcrumb.Item> */}
      <Breadcrumb.Item active> <span className='active_bc_item'>Projects</span></Breadcrumb.Item>
    </Breadcrumb>
  );
}

export const Dashboard = ({ server }) => {
  const [audits, set_audits] = useState([]);

  useEffect(() => {
    const get_audits = async () => {
      let audits = await server.get_domains();
      audits = [...new Set(audits.map(x => x.domain_name))];
      return audits;
    }

    get_audits().then(audits => set_audits(audits)).catch((e) => console.log(e));
  }, []);

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
  const data = [
    { name: "Group A", value: 400 },
    { name: "Group B", value: 300 },
    { name: "Group C", value: 300 },
    { name: "Group D", value: 200 },
  ];

  return (
    <DocumentTitle title="Dashboard - Lera cloud">
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div className={styles.breadcrumb_container}>{Breadcrumbs()}</div>
          <h2>All projects</h2>
          <div>
            <Card className={styles.project_box}>
              <Table>
                <thead>
                  <th>Projects</th>
                </thead>
                <tbody>
                  {audits.map((domain) => (
                    <tr className={styles.projectList} key={domain}>
                      <td>
                        <Link
                          to={"/analytics/" + domain}
                          style={{ textDecoration: "none", color: "white" }}
                          aria-label={"Analytics report for " + domain}>
                          {domain}
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
          {/* <div className={styles.bottom}>
            <Card className={styles.bottom_left}>
              <Card.Body>
                <h3 style={{ alignText: "center" }}>Scan Overview</h3>
                <Card.Text>Completed: www.advancedbytez.com UTC -4000</Card.Text>
                <Card.Text>Upcoming: No scan scheduled</Card.Text>

                <Card.Text>
                  <Link to={"/audits/create"}>
                    <span>Click  here to run an audit</span>
                  </Link>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className={styles.bottom_right}>
              <Card.Body>
                <h3 style={{ alignItems: "center" }}>Plan Overview</h3>
                <Card.Text>Warning</Card.Text>
                <Card.Text>92% of monthly qouta used.</Card.Text>
                <Card.Text>80 scans/1000 left for the month.</Card.Text>
              </Card.Body>
            </Card>
          </div> */}
        </div>
      </div>
    </DocumentTitle>
  );
};