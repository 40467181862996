import React, { useState, useEffect } from 'react';
import { Route, BrowserRouter } from 'react-router-dom';

import './scss/App.scss';
import Header from './Header';
import ServerAPI from './ServerAPI';
import { Switch } from 'react-router-dom';
import { SignInForm } from './components/SignInForm/SignInForm';
import { SignUpForm } from './components/SignUpForm/SignUpForm';
import { useCookies } from 'react-cookie';
import { SidebarNav } from './components/SidebarNav/SidebarNav';
import { OtpForm } from './components/OtpForm/OtpForm';
import { Dashboard } from './components/Dashboard/Dashboard';
import AuditForm from './audits/AuditForm/AuditForm';
import { AuditPage } from './audits/AuditPage/AuditPage';
import AuditStatus from './audits/AuditStatus/AuditStatus';
import Payments from './components/Payments/Payments';
import BillingHistory from './components/BillingHistory/BillingHistory';
import AnalyticsDomain from './analytics/AnalyticsDomain/Analytics_Domain';
import AnalyticsPage from './analytics/AnalyticsPage/Analytics_Page';
import SkipLink from './components/SkipLink/SkipLink';
import AuthScreen from './components/AuthScreen/AuthScreen';
import Admin from './components/Admin/Admin';
import UserPage from './components/Admin/UserPage/UserPage';
import AdminAuditPage from "./components/Admin/AuditPage/AdminAuditPage"
import { UserAudit } from './components/Admin/UserAudit/UserAudit';
import ChangePassword from './components/ChangePassword/ChangePassword';

let server = new ServerAPI();

const App = () => {

  const [cookies, setCookie, removeCookie] = useCookies(['user']);
  const [global, set_global] = useState({
    audits: [],
    teams: [],
    is_admin: false,
  });
  let mainRef = React.useRef(null);

  useEffect(() => {
    const initClarity = (id) => {
      (function (c, l, a, r, i, t, y) {
        c[a] =
          c[a] ||
          function () {
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r);
        t.async = 1;
        t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(window, document, "clarity", "script", id);
    };
    initClarity("g4w9rpfx8n")
  }, [])

  return (
    <BrowserRouter>
      {!cookies.user &&
        <Switch>
          <Route exact path='/' render={() => <AuthScreen />} />
          <Route exact path='/sign-up' render={() => (<SignUpForm setCookie={setCookie} server={server} />)} />
          <Route exact path='/sign-in' render={() => (<SignInForm setCookie={setCookie} server={server} />)} />
        </Switch>
      }
      {cookies.user && !cookies.user.verified && (
        <Route
          exact
          path='/otp'
          render={() => (
            <OtpForm
              cookies={cookies}
              setCookie={setCookie}
              removeCookie={removeCookie}
              server={server}
            />
          )}
        />
      )}
      {cookies.user && cookies.user.verified && (
        <>
          <SkipLink text='Skip to main content' ref={mainRef} />
          <div
            style={{ display: 'flex', flexDirection: 'row', height: '100vh' }}
          >
            <SidebarNav
              server={server}
              global={global}
              set_global={set_global}
            />
            <div
              style={{ width: '85%', display: 'flex', flexDirection: 'column' }}
            >
              <Header style={{ flex: 7 }} removeCookie={removeCookie} />
              <main tabIndex='-1' ref={mainRef} style={{ backgroundColor: `rgb(228, 228, 228)` }}>
                <Switch>
                  <Route
                    exact
                    path='/'
                    render={() => (
                      <Dashboard
                        server={server}
                        global={global}
                        set_global={set_global}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/admin"
                    render={() => (
                      <Admin server={server} />
                    )}
                  />
                  <Route
                    path="/admin/users"
                    exact
                    render={() => (
                      <UserPage server={server} />
                    )}
                  />
                  <Route
                    path="/passwordchange"
                    exact
                    render={() => (
                      <ChangePassword server={server} />
                    )}
                  />
                  <Route
                    path="/admin/users/:userId"
                    render={() => (
                      <UserAudit server={server} />
                    )}
                  />
                  <Route
                    exact
                    path="/admin/audits"
                    render={() => (
                      <AdminAuditPage server={server} />
                    )}
                  />
                  <Route
                    exact
                    path='/audits/create'
                    render={() => <AuditForm server={server} />}
                  />

                  <Route
                    path='/audits/:auditId/status'
                    render={() => <AuditStatus server={server} />}
                  />

                  <Route
                    path='/audits/'
                    render={() => <AuditPage server={server} />}
                  />

                  <Route
                    exact
                    path='/billing/'
                    render={() => (
                      <Payments
                        server={server}
                        global={global}
                        set_global={set_global}
                      />
                    )}
                  />

                  <Route
                    path='/billing/success'
                    render={() => <h1>Success</h1>}
                  />

                  <Route
                    path='/billing/cancel'
                    render={() => <h1>Canceled</h1>}
                  />
                  <Route
                    path='/BillingHistory'
                    render={() => (
                      <BillingHistory
                        server={server}
                        global={global}
                        set_global={set_global}
                      />
                    )}
                  />

                  <Route
                    path='/analytics/pages/:page_id'
                    render={() => (
                      <AnalyticsPage
                        server={server}
                        global={global}
                        set_global={set_global}
                      />
                    )}
                  />

                  <Route
                    path='/analytics/:project_name'
                    render={() => (
                      <AnalyticsDomain
                        server={server}
                        global={global}
                        set_global={set_global}
                      />
                    )}
                  />

                  <Route
                    path='/analytics'
                    render={() => (
                      <Dashboard
                        server={server}
                        global={global}
                        set_global={set_global}
                      />
                    )}
                  />
                </Switch>
              </main>
            </div>
          </div>
        </>
      )}
    </BrowserRouter>
  );
};

export default App;
